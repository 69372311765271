@import "../../HopeUI/assets/scss/bootstrap/functions";
@import "../../HopeUI/assets/scss/bootstrap/variables";
@import "../../HopeUI/assets/scss/bootstrap/utilities";

a[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

$utilities: map-merge(
  $utilities,
  (
    "width": (
      property: width,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "min-width": (
      property: min-width,
      class: min-w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "max-width": (
      property: max-width,
      class: max-w,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "viewport-width": (
      property: width,
      class: vw,
      values: (
        25: 25vw,
        50: 50vw,
        75: 75vw,
        100: 100vw
      )
    ),
    "min-viewport-width": (
      property: min-width,
      class: min-vw,
      values: (
        25: 25vw,
        50: 50vw,
        75: 75vw,
        100: 100vw
      )
    ),
    "max-viewport-width": (
      property: max-width,
      class: max-vw,
      values: (
        25: 25vw,
        50: 50vw,
        75: 75vw,
        100: 100vw
      )
    ),
    "height": (
      property: height,
      class: h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "min-height": (
      property: min-height,
      class: min-h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "max-height": (
      property: max-height,
      class: max-h,
      values: (
        25: 25%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto
      )
    ),
    "viewport-height": (
      property: height,
      class: vh,
      values: (
        25: 25vh,
        50: 50vh,
        75: 75vh,
        100: 100vh
      )
    ),
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (
        25: 25vh,
        50: 50vh,
        75: 75vh,
        100: 100vh
      )
    ),
    "max-viewport-height": (
      property: max-height,
      class: max-vh,
      values: (
        25: 25vh,
        50: 50vh,
        75: 75vh,
        100: 100vh
      )
    ),
  )
);
