// Default Sidebar Style
.sidebar {
    display: block;
    position: fixed;
    width: 100%;
    max-width: $navbar-vertical-width;
    top: 0;
    bottom: 0;
    z-index: $zindex-general + 10;
    transition: $navbar-vertical-transition;
    background-color: $white;
    @if($navbar-vertical-shadow-enable == true) {
        box-shadow: $navbar-vertical-shadow;
    }
    .navbar-brand {
        display: flex;
        padding: 1.125rem $spacer;
        svg{
            color: $primary;
        }
        .logo-title {
            margin-left: $spacer;
            color: $primary;
            margin-bottom: 0;
            transition: $navbar-vertical-transition;
            transform: translateX(0%);
            opacity: 1;
            color: $dark;
        }
    }
    .sidebar-header{
        border-bottom: $border-width solid tint-color($border-color, 20%);
        margin-bottom: $spacer * .75;
    }
    .sidebar-body {
        // background: inherit;
        padding: $spacer;
        overflow: hidden;
    }
    .data-scrollbar {
        max-height: 92vh;
    }
    &.sidebar-dark, &.sidebar-color{
        .navbar-brand {
            svg{
                color: $white;
            }
        }
    }
}

.sidebar-default {
    .sidebar-list {
        display: block;
        position: static;
        .navbar-nav {
            .nav-item {
                .nav-link:not(.disabled) {
                    display: flex;
                    white-space: nowrap;
                    span {
                        flex: 1;
                        transition: $navbar-vertical-transition;
                        margin-left: $spacer;
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
                .nav-link{
                    &.static-item{
                        .default-icon{
                            font-weight: 500;
                        }
                    }   
                    i.sidenav-mini-icon {
                        opacity: 0;
                        display: none;
                    }                 
                }
            }
        }
    }
    .sidebar-body {
        padding: 0;
        padding-right: .75rem;
    }
    .navbar-nav {
        .static-item {
            .mini-icon {display: none;}
        }
        .nav-item {
            transition: $navbar-vertical-transition;
            position: relative;
            margin-top: 2px;
            .nav-link {
                // text-transform: capitalize;
                padding: .625rem;
                padding-left: 1rem;
                &:not(.disabled) {
                    .right-icon {
                        transition: all 100ms ease-in-out;
                    }
                    color: $gray-600;
                    &.active,&[aria-expanded=true]{
                        @include sidebar-item-color-varients($white ,$primary);
                        .right-icon {
                            transition: all 100ms ease-in-out;
                            transform: rotate(90deg);
                        }
                    }
                    &:hover:not(.active):not([aria-expanded=true]) {
                        @include sidebar-nav-item-hover($primary);
                    }
                }
            }
            .sub-nav {
                list-style: none;
                padding: 0;
            }
        }
    }
}

.sidebar {
    // Default Sidebar Toggle Style 1
    .sidebar-toggle {
        position: absolute;
        right: -12px;
        top: 20px;
        cursor: pointer;
        background: $primary;
        color: $white;
        padding: 0.2rem;
        @if $enable-rounded == true {
            border-radius: $border-radius-lg;
        }
        @if $enable-shadows == true {
            box-shadow: $component-active-shadow;
        }
        &:hover {
            @if $enable-shadows == true {
                box-shadow: $component-hover-shadow;
            }
        }
        .icon {
            display: flex;
            transform: rotate(0);
            transition: $navbar-vertical-transition;
        }
    }
}

@include media-breakpoint-down(xl) {
    .sidebar {
        .sidebar-toggle {
            right: 18px;
        }
    }
}
